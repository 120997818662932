<template>
  <div>
    <v-card
      class="mb-10"
    >
      <v-card-title>
        Player
      </v-card-title>

      <v-card-text>
        <v-text-field
          label="Video ID"
          dense
          outlined
          class="mb-2"
          v-model="videoId"
        ></v-text-field>
        <v-btn
          class="mb-2"
          color="primary"
          @click="requestM3u8"
        >
          Request M3U8
        </v-btn>
      </v-card-text>

      <v-card-text>
        <v-text-field
          label="Streaming URL"
          dense
          outlined
          class="mb-2"
          v-model="streamingUrl"
        ></v-text-field>
        <v-btn
          class="mb-2 mr-4"
          color="primary"
          @click="applyStreamingUrl"
        >
          Apply Streaming Url
        </v-btn>

        <v-btn
          class="mb-2"
          color="orange"
          @click="resetStreamingUrl"
        >
          Reset Streaming Url
        </v-btn>

        <div style="width: 80%; height: 400px" v-if="video.streaming_url">
          <video-player
            fill
            :video="video"
          ></video-player>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Player Demo',
    }
  },
  data: () => ({
    streamingUrl: null,
    videoId: 'streaming-vod-1',
    video: {
      // youtube
      // type: 'youtube',
      // youtube_url: 'https://www.youtube.com/watch?v=iiX78NtKHu8',

      type: 'streaming_vod',
      streaming_url: null,
    },

    // null
    defaultStreamingUrl: null,

    // chicken
    // defaultStreamingUrl: 'https://media.ciao-chung.com/media-output/2022/07/chicken/chicken.m3u8',

    // grass
    // defaultStreamingUrl: 'https://media.ciao-chung.com/media-output/2022/07/grass/grass.m3u8',
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.streamingUrl = this.$storage.get('demoStreamingUrl') || this.defaultStreamingUrl
      this.video.streaming_url = this.streamingUrl
    },
    async applyStreamingUrl() {
      this.$storage.set('demoStreamingUrl', this.streamingUrl)
      this.video.streaming_url = null
      await this.$nextTick()
      this.video.streaming_url = this.streamingUrl
    },
    resetStreamingUrl() {
      this.$storage.delete('demoStreamingUrl')
      this.video.streaming_url = null
    },
    async requestM3u8() {
      try {
        const result = await this.$api.collection.mediaApi.m3u8(this.videoId)
        console.warn('requestM3u8', result)
        this.streamingUrl = result.m3u8 || null
      } catch (error) {
        console.warn(error)
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
